
  import { Component, Vue } from 'vue-property-decorator';
  import { clone } from 'lodash';

  import HipsterInput from '@/components/HipsterInput.vue';
  import HipsterSelector from '@/components/HipsterSelector.vue';
  import InspiniaGridItem from '@/components/InspiniaGridItem.vue';

  import { SearchInsightsParams } from '@/types/search';
  import { FETCH_INSIGHTS } from '@/store/insights';
  import { FETCH_TAGS } from '@/store/tags';
  import { Tag } from '@/types/entities';

  @Component({
    components: { HipsterInput, HipsterSelector, InspiniaGridItem },
  })
  export default class InsightsList extends Vue {
    public filters: SearchInsightsParams = {
      query: '',
      per_page: 12,
      desc: 1,
      page: 1,
      with: ['media'],
      tags: []
    };
    public tags: string[] = [];

    beforeMount() {
      this.search({});
      this.$store.dispatch(FETCH_TAGS);
    }

    public search(filter: SearchInsightsParams) {
      if (!filter.page) {
        filter.page = 1;
      }

      const params = Object.assign(clone(this.filters), filter);

      if (params.tags) {
        params.tags = (params.tags as Tag[]).map((tag: Tag) => tag.name);
      }

      this.$store.dispatch(FETCH_INSIGHTS, params);
    }
  }
